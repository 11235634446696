'use client'
import styled from '@emotion/styled'

interface Props {
  text?: string
  paddingTop?: string
}

export const NotFound = ({ text, paddingTop }: Props) => {
  return (
    <Container paddingTop={paddingTop}>
      <h2>404</h2>
      <span>{text ?? 'Sidan kunde inte hittas. Kanske har den ändrat namn?'}</span>
    </Container>
  )
}

const Container = styled.div<{ paddingTop?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding-top: ${({ paddingTop }) => paddingTop ?? '25vh'};

  h2 {
    color: ${({ theme }) => theme.onBackgroundSemiLight};
    font-size: 5rem;
    line-height: 1;
  }

  span {
    color: ${({ theme }) => theme.onBackgroundLight};

    font-size: 0.95rem;
    margin-top: 1rem;
    display: block;
  }
`
